import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const MottoPage = () => (
	<Layout
		meta={{
			title: "Contact",
			description: "Get in touch with Accento's team",
			keywords: "contact",
			path: "/contact",
		}}
		wide
	>
		<Snippet id="contact" />
	</Layout>
)

export default MottoPage
